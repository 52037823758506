import { sendGTMEvent } from '@/components/GoogleTagManager';
import { getCartState } from '@/stores/Cart/CartStore';
import { CartItem } from '@/stores/Cart/types';
import { getOrderState } from '@/stores/Order/OrderStore';
import { Group, Product } from '@/types/products';
import getCartItemPrice from 'utils/cart/getCartItemPrice';

import getProductsWithGroupInfos from '../utils/getProductsWithGroupInfos';

export interface AddToCartGTMProps {
  cartItem: CartItem;
  products?: Product[];
  groups?: Group[];
}

const sendAddToCartGTM = ({
  cartItem,
  products,
  groups,
}: AddToCartGTMProps) => {
  const order = getOrderState();
  const cart = getCartState();

  const productWithGroupInfos = getProductsWithGroupInfos(
    groups,
    products,
  ).find(({ product }) => cartItem.productId === product.id);
  const cartItemPrice = getCartItemPrice({
    cartItem,
    cart: cart.items,
    products,
  });
  const data = {
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          id: cartItem.template?.designId
            ? `${cartItem.productId}_${cartItem.template?.designId}`
            : cartItem.productId,
          google_business_vertical: 'retail',
          item_id: cartItem.uuid,
          item_name: cartItem.name,
          affiliation: undefined,
          coupon: undefined,
          currency: productWithGroupInfos?.product.price.currency,
          discount:
            getCartItemPrice({
              cartItem,
              cart: cart.items,
              products,
              withDiscount: false,
            }) - cartItemPrice,
          index: cart.items.findIndex((c) => c.uuid === cartItem.uuid),
          item_brand: process.env.NEXT_PUBLIC_PARTNER,
          item_category: productWithGroupInfos?.group.id,
          item_category2: undefined,
          item_category3: undefined,
          item_category4: undefined,
          item_category5: undefined,
          item_list_id: productWithGroupInfos?.group.labels.name,
          item_list_name: productWithGroupInfos?.group.labels.name,
          item_variant: cartItem.productId,
          location_id: order?.storeId,
          price: cartItemPrice,
          quantity: cartItem.quantity,
        },
      ],
    },
  };
  // eslint-disable-next-line no-console
  console.debug('GTM_UACommerce', data);
  sendGTMEvent({ ecommerce: null }); // Clear the previous ecommerce object.
  sendGTMEvent(data);
};

export default sendAddToCartGTM;
