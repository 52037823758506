import { CartItem } from '@/stores/Cart/types';
import { Product } from '@/types/products';

import getCartItemUnitPrice from './getCartItemUnitPrice';

const getCartItemPrice = ({
  cartItem,
  cart,
  products = [],
  withDiscount = true,
}: {
  cartItem: CartItem;
  cart?: CartItem[];
  products?: Product[];
  withDiscount?: boolean;
}): number => {
  const product = products.find(({ id }) => id === cartItem.productId);
  if (!product || !cart) return 0;
  return (
    getCartItemUnitPrice(cartItem.productId, cart, products, withDiscount) *
    cartItem.quantity
  );
};

export default getCartItemPrice;
