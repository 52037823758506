import { CartItem } from '@/stores/Cart/types';
import { Product } from '@/types/products';

const getCartItemUnitPrice = (
  productId: string,
  cart: CartItem[],
  products: Product[],
  withDiscount = true,
): number => {
  const productPrices = products.find(
    (productFetched) => productFetched.id === productId,
  );
  if (withDiscount) {
    const totalQuantity = cart
      .filter((cartItem) => cartItem.productId === productId)
      .reduce((sum, i) => sum + i.quantity, 0);
    if (productPrices && productPrices.price && productPrices.price.tiers) {
      // Find the tier that matches the total quantity
      const pricePerPrint = productPrices.price.tiers.find((tier) => {
        if (tier.to && totalQuantity >= tier.from && totalQuantity <= tier.to)
          return true;
        return !tier.to && totalQuantity >= tier.from;
      });
      // Return the unit price of the tier
      return pricePerPrint
        ? Math.round(pricePerPrint.unitPrice * 100) / 100
        : 0;
    }
    return 0;
  }

  const sortedTiers = productPrices?.price?.tiers?.sort(
    (tierA, tierB) => tierA.from - tierB.from,
  );
  const firstTier = sortedTiers?.[0];
  if (!firstTier) return 0;
  //  Return the first tier price since there is no discount
  return sortedTiers ? firstTier.unitPrice : 0;
};

export default getCartItemUnitPrice;
