import { sendGTMEvent } from '@/components/GoogleTagManager';
import { getOrderState } from '@/stores/Order/OrderStore';
import { Group, Product } from '@/types/products';

export interface SelectItemListGTMProps {
  index: number;
  group: Group;
  product: Product;
}

const sendSelectItemListGTM = ({
  index,
  group,
  product,
}: SelectItemListGTMProps) => {
  const order = getOrderState();

  const data = {
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: product.id,
          item_name: product.labels.name,
          affiliation: undefined,
          coupon: undefined,
          currency: product.price.currency,
          discount: undefined,
          index,
          item_brand: process.env.NEXT_PUBLIC_PARTNER,
          item_category: group.id,
          item_category2: undefined,
          item_category3: undefined,
          item_category4: undefined,
          item_category5: undefined,
          item_list_id: group.labels.name,
          item_list_name: group.labels.name,
          item_variant: product.id,
          location_id: order?.storeId,
          price: product.price.tiers[0]?.unitPrice,
          quantity: 1,
        },
      ],
    },
  };
  // eslint-disable-next-line no-console
  console.debug('GTM_UACommerce', data);
  sendGTMEvent({ ecommerce: null }); // Clear the previous ecommerce object.
  sendGTMEvent(data);
};

export default sendSelectItemListGTM;
