import { sendGTMEvent } from '@/components/GoogleTagManager';
import { getOrderState } from '@/stores/Order/OrderStore';
import { Group, Product } from '@/types/products';

import getProductsWithGroupInfos from '../utils/getProductsWithGroupInfos';

export interface ViewItemListGTMProps {
  group: Group;
  products: Product[];
}

const sendViewItemListGTM = ({ group, products }: ViewItemListGTMProps) => {
  const productWithGroupInfos = getProductsWithGroupInfos([group], products);

  const order = getOrderState();

  const data = {
    event: 'view_item_list',
    ecommerce: {
      items: productWithGroupInfos.map(({ product, group: g }, index) => ({
        item_id: product.id,
        item_name: product.labels.name,
        affiliation: undefined,
        coupon: order?.coupon?.value?.id,
        currency: product.price.currency,
        discount: undefined,
        index,
        item_brand: process.env.NEXT_PUBLIC_PARTNER,
        item_category: g.id,
        item_category2: undefined,
        item_category3: undefined,
        item_category4: undefined,
        item_category5: undefined,
        item_list_id: g.labels.name,
        item_list_name: g.labels.name,
        item_variant: product.id,
        location_id: order?.storeId,
        price: product.price.tiers[0]?.unitPrice,
        quantity: 1,
      })),
    },
  };
  // eslint-disable-next-line no-console
  console.debug('GTM_UACommerce', data);
  sendGTMEvent({ ecommerce: null }); // Clear the previous ecommerce object.
  sendGTMEvent(data);
};

export default sendViewItemListGTM;
