import sendAddPaymentInfoGTM from './UAecommerce/addPaymentInfo';
import sendAddShippingInfoGTM from './UAecommerce/addShippingInfo';
import sendAddToCartGTM from './UAecommerce/addToCart';
import sendBeginCheckoutGTM from './UAecommerce/beginCheckout';
import sendPurchaseGTM from './UAecommerce/purchase';
import sendRemoveFromCartGTM from './UAecommerce/removeFromCart';
import sendSelectItemListGTM from './UAecommerce/selectItem';
import sendViewCartGTM from './UAecommerce/viewCart';
import sendViewItemListGTM from './UAecommerce/viewItemList';
import { GTMLog, GTMLogEvent } from './types';

const sendGTMLog = ({ event, value: data }: GTMLog) => {
  switch (event) {
    case GTMLogEvent.addPaymentInfo:
      sendAddPaymentInfoGTM(data);
      break;
    case GTMLogEvent.addShippingInfo:
      sendAddShippingInfoGTM(data);
      break;
    case GTMLogEvent.addToCart:
      sendAddToCartGTM(data);
      break;
    case GTMLogEvent.beginCheckout:
      sendBeginCheckoutGTM(data);
      break;
    case GTMLogEvent.purchase:
      sendPurchaseGTM(data);
      break;
    case GTMLogEvent.removeFromCart:
      sendRemoveFromCartGTM(data);
      break;
    case GTMLogEvent.selectItem:
      sendSelectItemListGTM(data);
      break;
    case GTMLogEvent.viewCart:
      sendViewCartGTM(data);
      break;
    case GTMLogEvent.viewItemList:
      sendViewItemListGTM(data);
      break;
    default:
      break;
  }
};

export default sendGTMLog;
