import { CartItem } from '@/stores/Cart/types';
import { Product } from '@/types/products';

import getCartItemPrice from './getCartItemPrice';

const getTotalCartPrice = ({
  cart,
  products,
  withDiscount = true,
}: {
  cart?: CartItem[];
  products: Product[];
  withDiscount?: boolean;
}): number => {
  if (!cart) return 0;
  const total = cart.reduce(
    (accumulator, cartItem) =>
      accumulator +
      getCartItemPrice({ cartItem, cart, products, withDiscount }),
    0,
  );

  return Math.round(total * 100) / 100;
};

export default getTotalCartPrice;
