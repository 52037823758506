import { sendGTMEvent } from '@/components/GoogleTagManager';
import { getCartState } from '@/stores/Cart/CartStore';
import { getOrderState } from '@/stores/Order/OrderStore';
import { Group, Product } from '@/types/products';
import getCartItemPrice from '@/utils/cart/getCartItemPrice';

import getProductsWithGroupInfos from '../utils/getProductsWithGroupInfos';

export interface AddShippingInfoProps {
  products: Product[];
  groups: Group[];
}

const sendAddShippingInfoGTM = async ({
  products,
  groups,
}: AddShippingInfoProps) => {
  const order = getOrderState();
  const cart = getCartState();

  const productsWithGroupInfos = getProductsWithGroupInfos(groups, products);
  const getProductWithGroupInfos = (productId: string) =>
    productsWithGroupInfos.find(
      (productWithGroupInfos) => productWithGroupInfos.product.id === productId,
    );
  const data = {
    event: 'add_shipping_info',
    ecommerce: {
      currency: productsWithGroupInfos[0]?.product.price.currency,
      value: 0,
      shipping_tier: 'Pickup',
      items: cart.items.map((cartItem, index) => {
        const productWithGroupInfos = getProductWithGroupInfos(
          cartItem.productId,
        );
        const cartItemPrice = getCartItemPrice({
          cartItem,
          cart: cart.items,
          products,
        });
        return {
          item_id: cartItem.uuid,
          item_name: cartItem.name,
          affiliation: undefined,
          coupon: order?.coupon?.value?.id,
          currency: productWithGroupInfos?.product.price.currency,
          discount:
            getCartItemPrice({
              cartItem,
              cart: cart.items,
              products,
              withDiscount: false,
            }) - cartItemPrice,
          index,
          item_brand: process.env.NEXT_PUBLIC_PARTNER,
          item_category: productWithGroupInfos?.group.id,
          item_category2: undefined,
          item_category3: undefined,
          item_category4: undefined,
          item_category5: undefined,
          item_list_id: productWithGroupInfos?.group.labels.name,
          item_list_name: productWithGroupInfos?.group.labels.name,
          item_variant: cartItem.productId,
          location_id: order?.storeId,
          price: cartItemPrice,
          quantity: cartItem.quantity,
        };
      }),
    },
  };
  // eslint-disable-next-line no-console
  console.debug('GTM_UACommerce', data);
  sendGTMEvent({ ecommerce: null }); // Clear the previous ecommerce object.
  sendGTMEvent(data);
};

export default sendAddShippingInfoGTM;
