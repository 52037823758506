import { Group, Product } from '@/types/products';

interface ProductWithGroupInfos {
  product: Product;
  group: Group;
}

const getProductWithGroupInfos = (
  groups: Group[] = [],
  products: Product[] = [],
) => {
  const productWithGroupInfos: ProductWithGroupInfos[] = [];
  groups.forEach((group) =>
    group.products.forEach(({ id }) => {
      const product = products.find(({ id: productId }) => id === productId);
      if (product)
        productWithGroupInfos.push({
          product,
          group,
        });
    }),
  );
  return productWithGroupInfos;
};

export default getProductWithGroupInfos;
